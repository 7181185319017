import TrafficAccident1 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\2020\uB144\uAC04_\uC870\uC0AC\uB41C_\uAD50\uD1B5\uC0AC\uACE0_\uD53C\uD574\uBCC4_\uD569\uC0B0.js";
import TrafficAccident2 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uC8FC\uC57C\uC911_\uAD50\uD1B5\uC0AC\uACE0\uAC00_\uC77C\uC5B4\uB09C_\uBE44\uC728.js";
import TrafficAccident3 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uC694\uC77C\uBCC4_\uC0AC\uACE0_\uD69F\uC218.js";
import TrafficAccident4 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uC694\uC77C\uBCC4 \uC0AC\uACE0 \uD69F\uC218 (\uC885\uD569).js";
import TrafficAccident5 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uC0AC\uACE0\uC720\uD615_\uB300\uBD84\uB958.js";
import TrafficAccident6 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uAD50\uD1B5\uC0AC\uACE0_\uC720\uD615\uBCC4_\uC0AC\uACE0_\uD69F\uC218.js";
import TrafficAccident7 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uB300\uBD84\uB958_\uC720\uD615\uBCC4_\uC0AC\uACE0.js";
import TrafficAccident8 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uAC00\uD574\uC790\uC758_\uAD50\uD1B5_\uC218\uB2E8\uBCC4_\uC0AC\uACE0_\uD69F\uC218.js";
import TrafficAccident9 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uD53C\uD574\uC790\uC758_\uAD50\uD1B5_\uC218\uB2E8\uBCC4_\uC0AC\uACE0_\uD69F\uC218.js";
import TrafficAccident10 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uAC00\uD574\uC790_\uD53C\uD574\uC790_\uC0C1\uD669\uBCC4_\uC0AC\uACE0_\uD69F\uC218.js";
import TrafficAccident11 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uAC00\uD574\uC790_\uD53C\uD574\uC790_\uC704\uBC18\uBC95\uADDC_\uC0AC\uACE0\uC720\uD615.js";
import TrafficAccident12 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uC2DC\uB3C4\uBCC4_\uAD50\uD1B5\uC0AC\uACE0_\uBC1C\uC0DD_\uBE48\uB3C4.js";
import TrafficAccident13 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-trafficAccident\\App\\\uC9C0\uC5ED\uBCC4_\uC0AC\uACE0\uAC74\uC218.js";
import * as React from 'react';
export default {
  TrafficAccident1,
  TrafficAccident2,
  TrafficAccident3,
  TrafficAccident4,
  TrafficAccident5,
  TrafficAccident6,
  TrafficAccident7,
  TrafficAccident8,
  TrafficAccident9,
  TrafficAccident10,
  TrafficAccident11,
  TrafficAccident12,
  TrafficAccident13,
  React
};