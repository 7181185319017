import Welfare1 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uC11C\uC6B8\uBCF5\uC9C0\uC2DC\uC124\uC218.js";
import Welfare2 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uB178\uC6D0\uAD6C\uBCF5\uC9C0\uC2DC\uC124.js";
import Welfare3 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uC1A1\uD30C\uAD6C\uBCF5\uC9C0\uC2DC\uC124.js";
import Welfare4 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uC911\uAD6C\uBCF5\uC9C0\uC2DC\uC124.js";
import Welfare5 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uAC15\uB0A8\uAD6C\uBCF5\uC9C0\uC2DC\uC124.js";
import Welfare6 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uBCF5\uC9C0\uC2DC\uC124\uC885\uB958.js";
import Welfare7 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uC11C\uC6B8\uC2DC\uB178\uC778\uBCF5\uC9C0\uC2DC\uC124\uC885\uB958.js";
import Welfare8 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uC11C\uC6B8\uC2DC\uC7A5\uC560\uC778\uBCF5\uC9C0\uC2DC\uC124\uC885\uB958.js";
import Welfare10 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uAE30\uD0C0\uBCF5\uC9C0\uC2DC\uC124_\uBC14.js";
import Welfare11 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uAE30\uD0C0\uBCF5\uC9C0\uC2DC\uC124_\uD30C\uC774.js";
import Welfare12 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uC8FC\uC694\uBCF5\uC9C0\uC2DC\uC124_\uBC14.js";
import Welfare13 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-Welfare\\App\\\uC8FC\uC694\uBCF5\uC9C0\uC2DC\uC124_\uD30C\uC774.js";
import * as React from 'react';
export default {
  Welfare1,
  Welfare2,
  Welfare3,
  Welfare4,
  Welfare5,
  Welfare6,
  Welfare7,
  Welfare8,
  Welfare10,
  Welfare11,
  Welfare12,
  Welfare13,
  React
};