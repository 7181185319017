import MedicalInformation1 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC6D4\uBCC4_4\uB300_\uC9C8\uBCD1_\uD3C9\uADE0.js";
import MedicalInformation2 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC6D4\uBCC4_\uAC10\uAE30_\uD3C9\uADE0_line.js";
import MedicalInformation3 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC6D4\uBCC4_\uAC10\uAE30_\uD3C9\uADE0_bar.js";
import MedicalInformation4 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC6D4\uBCC4_\uB208\uBCD1_\uD3C9\uADE0_line.js";
import MedicalInformation5 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC6D4\uBCC4_\uB208\uBCD1_\uD3C9\uADE0_bar.js";
import MedicalInformation6 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC6D4\uBCC4_\uCC9C\uC2DD_\uD3C9\uADE0_line.js";
import MedicalInformation7 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC6D4\uBCC4_\uCC9C\uC2DD_\uD3C9\uADE0_bar.js";
import MedicalInformation8 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC6D4\uBCC4_\uD53C\uBD80\uC5FC_\uD3C9\uADE0_line.js";
import MedicalInformation9 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC6D4\uBCC4_\uD53C\uBD80\uC5FC_\uD3C9\uADE0_bar.js";
import MedicalInformation10 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC5F0\uB3C4\uBCC4_4\uB300_\uC9C8\uBCD1_\uD3C9\uADE0.js";
import MedicalInformation11 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC5F0\uB3C4\uBCC4_\uAC10\uAE30\uBC1C\uC0DD\uAC74\uC218_\uD3C9\uADE0.js";
import MedicalInformation12 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC5F0\uB3C4\uBCC4_\uB208\uBCD1\uBC1C\uC0DD\uAC74\uC218_\uD3C9\uADE0.js";
import MedicalInformation13 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC5F0\uB3C4\uBCC4_\uCC9C\uC2DD\uBC1C\uC0DD\uAC74\uC218_\uD3C9\uADE0.js";
import MedicalInformation14 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-10-MedicalInformation\\App\\\uC5F0\uB3C4\uBCC4_\uD53C\uBD80\uC5FC\uBC1C\uC0DD\uAC74\uC218.js";
import * as React from 'react';
export default {
  MedicalInformation1,
  MedicalInformation2,
  MedicalInformation3,
  MedicalInformation4,
  MedicalInformation5,
  MedicalInformation6,
  MedicalInformation7,
  MedicalInformation8,
  MedicalInformation9,
  MedicalInformation10,
  MedicalInformation11,
  MedicalInformation12,
  MedicalInformation13,
  MedicalInformation14,
  React
};