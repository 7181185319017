import App1 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-08-cctv\\App1.js";
import App2 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-08-cctv\\App2.js";
import App3 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-08-cctv\\App3.js";
import App4 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-08-cctv\\App4.js";
import App5 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-08-cctv\\App5.js";
import App6 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-08-cctv\\App6.js";
import App7 from "..\\..\\..\\..\\src\\content\\posts\\2021-12-08-cctv\\App7.js";
import * as React from 'react';
export default {
  App1,
  App2,
  App3,
  App4,
  App5,
  App6,
  App7,
  React
};