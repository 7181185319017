import Kinder1 from "..\\..\\..\\..\\src\\content\\posts\\2022-02-22-kindergarten\\App\\\uC2DC\uB3C4\uBCC4\uC5B4\uB9B0\uC774\uC9D1\uC218.js";
import Kinder2 from "..\\..\\..\\..\\src\\content\\posts\\2022-02-22-kindergarten\\App\\\uC2DC\uB3C4\uBCC4\uC5B4\uB9B0\uC774\uC9D1\uC6B4\uC601\uD604\uD669.js";
import Kinder3 from "..\\..\\..\\..\\src\\content\\posts\\2022-02-22-kindergarten\\App\\\uC5F0\uB3C4\uBCC4\uACBD\uB0A8\uD3D0\uC9C0\uC218.js";
import Kinder4 from "..\\..\\..\\..\\src\\content\\posts\\2022-02-22-kindergarten\\App\\\uC5B4\uB9B0\uC774\uC9D1\uC720\uD615.js";
import Kinder5 from "..\\..\\..\\..\\src\\content\\posts\\2022-02-22-kindergarten\\App\\\uC2DC\uB3C4\uBCC4\uD1B5\uD559\uCC28\uB7C9.js";
import Kinder6 from "..\\..\\..\\..\\src\\content\\posts\\2022-02-22-kindergarten\\App\\\uC720\uD615\uACFC\uC6B4\uC601\uD604\uD669\uC758\uAD00\uACC4.js";
import Kinder7 from "..\\..\\..\\..\\src\\content\\posts\\2022-02-22-kindergarten\\App\\\uC5F0\uB3C4\uBCC4\uC5B4\uB9B0\uC774\uC9D1\uC778\uAC00\uC218.js";
import Kinder8 from "..\\..\\..\\..\\src\\content\\posts\\2022-02-22-kindergarten\\App\\\uC5F0\uB3C4\uBCC4\uC5B4\uB9B0\uC774\uC9D1\uD3D0\uC9C0\uC218.js";
import Kinder9 from "..\\..\\..\\..\\src\\content\\posts\\2022-02-22-kindergarten\\App\\\uC9C0\uB3C4.js";
import * as React from 'react';
export default {
  Kinder1,
  Kinder2,
  Kinder3,
  Kinder4,
  Kinder5,
  Kinder6,
  Kinder7,
  Kinder8,
  Kinder9,
  React
};